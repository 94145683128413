import { Model } from '../../Model';
import { SchemaController } from '../../controllers/schema';
import { UserController } from '../user';
import { MetaController } from '../../controllers/meta';
import { CollectionController } from '../collection';
import { DashboardController } from './dashboard.controller';

export const DashboardView = {
    temp: function() {
        // var digitalPropertiesElem = $('<div/>')
        //     .addClass('digital-properties');

        // var recentActivityElem = $('<div/>')
        //     .addClass('recent-activity')
        //     .on('click', function() {
        //         $('.dashboard').html('');

        //         flat.renderTemplate({
        //             src: Model.serverDomain+'/template/test/index.html'
        //         }, {

        //         }).then(function(templateResults) {
        //             // console.log(templateResults);
        //             $('.dashboard').html(templateResults);
        //         });

        //     });

        var toolsetElem = $('<div/>')
            .addClass('toolset');

        var permissionKey;
        $.each(SchemaController.getSchemas(), function(schemaName, schema) {
            // Verify that the active user has permission to view this schema
            permissionKey = 'Collection:'+schema.collectionName;
            // console.log('checkPermission: '+permissionKey);
            // console.log(UserController.checkPermission(['developer', permissionKey]));
            if (UserController.checkPermission(['developer', permissionKey])) {
                toolsetElem.append(DashboardView.menuItem({schemaName, schema}))
            }
        });
        if (UserController.checkPermission(['developer'])) {
            toolsetElem.append(DashboardView.menuItem({
                initFunction: () => {
                    window.location.href = process.env.RV_INVENTORY_PLATFORM;
                }, 
                schema: {displayName: 'RV Inventory Platform', icon: 'sack-dollar-solid.svg',}
            }))
        }

        var dashboardElem = $('<div/>')
            .addClass('dashboard')
            // .append(recentActivityElem)
            // .append(digitalPropertiesElem)
            .append(toolsetElem)

        return [
            DashboardView.header(),
            dashboardElem
        ];
    },
    
    menuItem({schemaName, schema, initFunction}) {
        return $(`
            <div class="menu-item">
                <h2>${schema.displayName}</h2>
                
                <div class="schema-icon-wrapper">
                    ${schema.icon
                        ? `<img src="img/${schema.icon}" class="schema-icon" alt="">`
                        : ''
                    }
                </div>
            </div>
        `)
        .on('click', function() {
            if (schemaName) {
                CollectionController.init(schemaName);
            } else if (initFunction) {
                initFunction();
            }
        })
    },
    
    header() {
        return $('<div/>')
            .addClass('header')
            // .text(Controller.Meta.getSystemTitle())
            .append(
                $(`<h1 class="title">${MetaController.getSystemTitle()}</h1>`)
                .on('click', () => DashboardController.init())
            )
            .append(
                $('<div/>')
                    .addClass('logout')
                    .text(UserController.getActiveUser().email+' - Logout')
                    .on('click', function() {
                        UserController.logout();
                    })
            );
    }
}