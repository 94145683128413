import { Model } from '../../Model';

export const SwitchController = {
    getAll: function() {
        return Model.switches;
    },
    activate: function(switchMeta, callback) {
        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/switch/activate',
            data: {
                switchMeta: switchMeta
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                callback();
            },
            error: function(data) {
                console.error(data.responseJSON.message);
                alert(data.responseJSON.message);
            }
        });
    }
}