export const ModalView = {
    init: function(title, content, onInit, onClose) {
        if (onInit == null) {
            onInit = function() {};
        }

        if (onClose == null) {
            onClose = function() {};
        }

        $('body')
            .append(
                $('<div/>')
                    .addClass('modal-wrapper')
                    .append(
                        $('<div/>')
                            .addClass('modal')
                            .append(
                                $('<div/>')
                                    .addClass('header')
                                    .html(title)
                                    .prepend(
                                        $('<div/>')
                                            .addClass('close')
                                            .text('X')
                                            .on('click', function() {
                                                ModalView.close(onClose);
                                            })
                                    )
                            )
                            .append(
                                $('<div/>')
                                    .addClass('content')
                                    .html(content)
                            )
                            .on('click', function(event) {
                                event.stopPropagation();
                            })
                    )
                    .on('click', function() {
                        ModalView.close(onClose);
                    })
            );
        setTimeout(function() {
            $('.modal-wrapper').addClass('active');
            onInit();
        }, 20);
    },
    close: function(callback) {
        callback();
        $('.modal-wrapper').removeClass('active');
        setTimeout(function() {
            $('.modal-wrapper').remove();
        }, 400);
    }
}