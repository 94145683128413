import { Model } from '../../Model';
import { Controller } from '../../Controller';
import { MetaController } from '../../controllers/meta';
import { SchemaController } from '../../controllers/schema';
import { UserController } from '../user';
import { CollectionView, CollectionController } from '../collection';
import { ItemView } from './item.view';

export const ItemController = {
    add: function() {
        var activeSchema = SchemaController.getActiveSchema();

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/add',
            data: {
                schemaName: activeSchema.schemaName
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                ItemController.load(data.insertedDoc.insertedId, function() {

                });
            }
        });
    },
    delete: function(itemId) {
        var activeSchema = SchemaController.getActiveSchema();

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/delete',
            data: {
                collectionName: activeSchema.collectionName,
                itemId: itemId
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                $('.item').replaceWith(CollectionView.dom());
                CollectionController.load(activeSchema);
            }
        });
    },
    duplicate: function(itemId) {
        var activeSchema = SchemaController.getActiveSchema();

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/duplicate',
            data: {
                collectionName: activeSchema.collectionName,
                itemId: itemId,
                itemPath: activeSchema.itemPath
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                //console.log(data);
                //$('.item').replaceWith(CollectionView.dom());
                //CollectionController.load(activeSchema);
                ItemController.load(data.insertedDoc.insertedId, function() {

                });

            }
        });
    },
    get: function(itemId, schema, callback, errorCallback) {
        if (!callback) {
            callback = function() {};
        }
        if (!errorCallback) {
            errorCallback = function() {};
        }
        
        if (!schema) {
            schema = SchemaController.getActiveSchema();
        }
        if (typeof schema == 'string') {
            schema = SchemaController.get(schema);
        }

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/load',
            data: {
                schemaName: schema.schemaName,
                collectionName: schema.collectionName,
                itemId: itemId
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                var item = data.records[0];
                callback(item);
            },
            error: function(jqXHR) {
                console.error('error in item load:', jqXHR);
                errorCallback(jqXHR);
            }
        });
    },
    
    load: function(itemId, callback) {
        let schema = SchemaController.getActiveSchema().schemaName;
                
        ItemController.get(
            itemId,
            schema, 
            item => {
                ItemController.render(item);
                
                if (typeof callback == 'function') callback();
                else console.warn('No callback specified for item.load()');
            },
            jqXHR => {
                //alert("Sorry, you are not logged in.");
                window.location = window.location;
            }
        );
    },
    
    render: function(item) {
        Model.activeItem = item;
        Model.beforeSaveHandlers = {};
        Model.afterSaveHandlers = {};
        $('.item, .collection').replaceWith(ItemView.dom());
        // $('.collection').replaceWith(ItemView.dom());
        
        // * With the data reorg, we can probably just use the regular User IDs instead of nfcIds
        // // if ( schema.schemaName == 'customClassUser' && !item.nfcId ) {
        // //     console.log('getting new custom class user ID', item);
            
        // //     UserController.getNewId(function(newId) {
        // //         if (newId && $('.nfcId').val() == ""){
        // //             $('.nfcId').val(newId);
        // //             $('.nfcId').prop('disabled', true);
                    
        // //             ItemController.save('nfcId', newId, 'text');
        // //         }
        // //     });
        // // }

        ItemController.itemsSortable('.items');
        tinyMCE.init({
            selector: '.textarea-html',
            menubar: false,
            plugins: 'lists paste link code',
            paste_as_text: true,
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
            //skin_url: 'node_modules/tinymce/skins/lightgray',
            branding: false,
            default_link_target: "_blank",
            resize: false,
            statusbar: false,
            setup: function(ed) {
                ed.on('change', function(e) {
                    $(ed.getElement()).trigger('saveme', ed.getContent());
                });
            }
        });
    },
    
    save: function(path, value, type, callback) {
        if (!callback) {
            callback = function() {};
        }
        var activeSchema = SchemaController.getActiveSchema();
        if (typeof value == 'string') {
            value = value.replace(/[\n\r]+/g, '').trim();
        }

        // Change value in Model
        //console.log(path);
        //console.log(ItemController.getActiveItem());
        const item = ItemController.getActiveItem();
        const oldValue = MetaController.getByPath(item, path);
        MetaController.setToValue(item, value, path);
        ItemController.runBeforeSaveHandlers(path, value, oldValue);


        var saveKey = MetaController.getHash(activeSchema.collectionName+'-'+path);
        // console.log(saveKey);

        if (Model.saveTimers[saveKey]) {
            clearTimeout(Model.saveTimers[saveKey]);
        }


        if (Model.saveRequests[saveKey]) {
            Model.saveRequests[saveKey].abort();
        }

        Model.saveTimers[saveKey] = setTimeout(function() {
            Model.saveRequests[saveKey] = $.ajax({
                type: 'POST',
                url: Model.serverDomain+'/item/saveField',
                data: {
                    collectionName: activeSchema.collectionName,
                    id: Model.activeItem._id,
                    path: path,
                    value: value,
                    type: type
                },
                beforeSend: function(xhr) {
                    if (localStorage.token) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                    }
                },
                success: function(data) {
                    //ItemView.showSuggestions(path, data.suggestions);
                    callback();
                    ItemController.runAfterSaveHandlers(path, value, oldValue);
                }
            });
        }, 1000);
    },
    findOrCreate: function(schema, path, value, callback) {
        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/findCreate',
            data: {
                collectionName: schema.collectionName,
                path: path,
                value: value
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                callback(data.id);
            },
            error: function() {
                //alert("Sorry, you are not logged in.");
                window.location = window.location;
            }
        });
    },
    find: function(schema, path, value, callback) {
        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/findCreate',
            data: {
                collectionName: schema.collectionName,
                path: path,
                value: value
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                callback(data.id);
            },
            error: function() {
                //alert("Sorry, you are not logged in.");
                window.location = window.location;
            }
        });
    },
    itemsSortable: function(selector) {
        $(selector).sortable({
            axis: 'y',
            //containment: 'parent',
            start: function(e, ui) {
                ui.item.data('originIndex', ui.item.index());
            },
            stop: function(e, ui) {
                var startIndex = ui.item.data('originIndex');
                var endIndex = ui.item.index();
                //console.log(startIndex+' -> '+endIndex);
                //console.log(ui.item.parent().data('path'));

                ItemController.moveArrayItem(ui.item.parent().data('path'), startIndex, endIndex);



            }
        });
    },
    addArrayItem: function(options, callback = () => null) {
        var activeSchema = SchemaController.getActiveSchema();
        //var activeItem = Model.activeItem;
        //MetaController.getByPath(activeItem, path).push({});

        var targetArray = MetaController.getRefByPath(options.path, ItemController.getActiveItem());
        var oldArray = [];
        // List types will insert an empty object
        var value = options.value || {};
        
        if (Array.isArray(targetArray)) {
            oldArray = [...targetArray];
            targetArray.push(value);
        } else {
            MetaController.setToValue(ItemController.getActiveItem(), [value], options.path);
            //targetArray = [{}];
        }

        //console.log(ItemController.getActiveItem());

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/addArrayItem',
            data: {
                collectionName: activeSchema.collectionName,
                id: Model.activeItem._id,
                path: options.path,
                value: value
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                callback();
                console.log({targetArray});
                ItemController.runAfterSaveHandlers(options.path, targetArray, oldArray);
            }
        });
    },
    deleteArrayItem: function(options, index, callback = () => null) {
        var activeSchema = SchemaController.getActiveSchema();
        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/deleteArrayItem',
            data: {
                collectionName: activeSchema.collectionName,
                id: Model.activeItem._id,
                path: options.path,
                index: index
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                callback();
            }
        });
    },
    moveArrayItem: function(path, oldIndex, newIndex) {

        var activeItemArray = MetaController.getRefByPath(path, ItemController.getActiveItem());
        MetaController.arrayMove(activeItemArray, oldIndex, newIndex);

        var activeSchema = SchemaController.getActiveSchema();
        var saveKey = MetaController.getHash(activeSchema.collectionName+'-'+path);
        console.log(saveKey);

        if (Model.saveTimers[saveKey]) {
            clearTimeout(Model.saveTimers[saveKey]);
        }


        if (Model.saveRequests[saveKey]) {
            Model.saveRequests[saveKey].abort();
        }

        console.log('Move Array Item: from '+oldIndex+' to '+newIndex);
        // Re-init event hooks
        console.log(path);
       // $('.field-'+path).replaceWith();


        Model.saveTimers[saveKey] = setTimeout(function() {
            Model.saveRequests[saveKey] = $.ajax({
                type: 'POST',
                url: Model.serverDomain+'/item/moveArrayItem',
                data: {
                    collectionName: activeSchema.collectionName,
                    id: Model.activeItem._id,
                    path: path,
                    oldIndex: oldIndex,
                    newIndex: newIndex
                },
                beforeSend: function(xhr) {
                    if (localStorage.token) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                    }
                },
                success: function(data) {


                    // TODO: Update indexes for save instead of reloading item. Increase timeout delay after this fix.
                    var recordContainerScroll = $('.record-container').scrollTop();
                    ItemController.load(Model.activeItem._id, function() {
                        $('.record-container').scrollTop(recordContainerScroll);
                    });


                }
            });
        }, 10);
    },
    getSuggestions: function(options, value, itemId) {
        var activeSchema = SchemaController.getActiveSchema();
        var filters = [];
        $.each(options.suggestionFilters, function(fp, filterPath) {
            var filterVal = MetaController.getByPath(ItemController.getActiveItem(), filterPath);
            if (filterVal != '') {
                filters.push({
                    path: filterPath,
                    value: filterVal
                });
            }
        });
        
        let collectionName = activeSchema.collectionName;
        let path = options.path;
        
        if (options.linkedInfo && options.linkedInfo.schema && options.linkedInfo.path) {
            let linkedSchema = SchemaController.get(options.linkedInfo.schema);
            
            if (linkedSchema) {
                collectionName = linkedSchema.collectionName;
                path = options.linkedInfo.path;
            }
        }

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/item/getSuggestions',
            data: {
                collectionName,
                path: path.replace(/\[.*.\]/g, ''),
                value: value,
                itemId: itemId,
                filters: filters
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                ItemView.showSuggestions(options, data.suggestions, value);
            }
        });
    },
    getActiveItem: function() {
        return Model.activeItem;
    },
    savePassword: function(value, callback) {
        if (!callback) {
            callback = function() {};
        }
        var activeSchema = SchemaController.getActiveSchema();
        if (typeof value == 'string') {
            value = value.replace(/[\n\r]+/g, '').trim();
        }


        var saveKey = MetaController.getHash(activeSchema.collectionName+'-password');

        if (Model.saveTimers[saveKey]) {
            clearTimeout(Model.saveTimers[saveKey]);
        }

        if (Model.saveRequests[saveKey]) {
            Model.saveRequests[saveKey].abort();
        }

        Model.saveTimers[saveKey] = setTimeout(function() {
            Model.saveRequests[saveKey] = $.ajax({
                type: 'POST',
                url: Model.serverDomain+'/item/savePassword',
                data: {
                    collectionName: activeSchema.collectionName,
                    id: Model.activeItem._id,
                    value: value
                },
                beforeSend: function(xhr) {
                    if (localStorage.token) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                    }
                },
                success: function(data) {
                    callback();
                }
            });
        }, 100);
    },
    
    addAfterSaveHandler(path, handler) {
        if (! Array.isArray( Model.afterSaveHandlers[path] ) ) Model.afterSaveHandlers[path] = [];
        Model.afterSaveHandlers[path].push(handler);
    },
    
    runAfterSaveHandlers(path, value, oldValue) {
        if ( Array.isArray(Model.afterSaveHandlers[path]) ) {
            for (const handler of Model.afterSaveHandlers[path]) {
                if (typeof handler == 'function') handler(value, oldValue, Controller);
            }
        }
        
        if ( Array.isArray(Model.afterSaveHandlers['*']) ) {
            for (const handler of Model.afterSaveHandlers['*']) {
                if (typeof handler == 'function') handler(value, oldValue, Controller);
            }
        }
    },
    
    addBeforeSaveHandler(path, handler) {
        if (! Array.isArray( Model.beforeSaveHandlers[path] ) ) Model.beforeSaveHandlers[path] = [];
        Model.beforeSaveHandlers[path].push(handler);
    },
    
    runBeforeSaveHandlers(path, value, oldValue) {
        if ( Array.isArray(Model.beforeSaveHandlers[path]) ) {
            for (const handler of Model.beforeSaveHandlers[path]) {
                if (typeof handler == 'function') handler(value, oldValue, Controller);
            }
        }
        
        if ( Array.isArray(Model.beforeSaveHandlers['*']) ) {
            for (const handler of Model.beforeSaveHandlers['*']) {
                if (typeof handler == 'function') handler(value, oldValue, Controller);
            }
        }
    }
}