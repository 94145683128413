import { Model } from '../Model';

export const MetaController = {
    getSystemTitle: function() {
        return Model.systemTitle;
    },
    getUrlParam: function(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null){
           return null;
        }
        else{
           return decodeURI(results[1]) || 0;
        }
    },
    getByPath: function (o, path, flattenArrays) {
        if (!flattenArrays) {
            flattenArrays = false;
        }
        //console.log('o:');
        //console.log(o);
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, '');           // strip a leading dot
        var aPath = path.split('.');
        for (var i = 0, n = aPath.length; i < n; ++i) {
            if (o == null) {
                //console.log('Cannot get path of undefined.');
                return '';
            }

            if ((flattenArrays) && (Array.isArray(o))) {
                var csv = '';
                for (var m=0; m<o.length; m++) {
                    csv += MetaController.getByPath(o[m], aPath[i])+', ';
                }
                return csv.slice(0, -2);
            }

            var k = aPath[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    },
    getRefByPath: function(path, obj) {
        return path.split('.').reduce(function(prev, curr) {
            return prev ? prev[curr] : null;
        }, obj || self);
    },
    setToValue: function(obj, value, path) {
        path = path.replace(/\[(\w+)\]/g, '.$1');
        var i;
        path = path.split('.');
        for (i = 0; i < path.length - 1; i++) {
            if (obj[path[i]] == undefined) {
                obj[path[i]] = {};
            }
            obj = obj[path[i]];
        }

        obj[path[i]] = value;
    },
    dashPath: function(dotPath) {
        return dotPath.replace(/\.|\[/g,'-').replace(/]/g, '');
    },
    clone: function(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = MetaController.clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = MetaController.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    },
    getHash: function(string) {
        var hash = 0, i, chr;
        if (string.length === 0) return hash;
        for (i = 0; i < string.length; i++) {
            chr   = string.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },
    arrayMove: function(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
            var k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

        return arr;
    }
}