import { Model } from '../Model';
import { MetaController } from './meta';
import { CollectionController } from '../modules/collection';

export const SchemaController = {
    get: function(collectionName) {
        return Model.schemas[collectionName];
    },
    getSchemas: function() {
        return Model.schemas;
    },
    setActiveSchema: function(schema) {
        // console.dir(schema)
        
        Model.activeSchema = schema;
        Model.activeSchemaColumns = new Map(schema.defaultColumns);
        Model.activeSchemaSort = {...schema.defaultSort};
        Model.activeSchemaFilters = {};
        SchemaController.setColumnWidths();
    },
    
    setColumnWidths: function() {
        const columns = Model.activeSchemaColumns;
        let flexWidthTotal = 0;
        const columnFlexes = new Map();
        
        for (const column of columns.values()) {
            const flex = column.flex || 1;
            flexWidthTotal += flex;
            
            columnFlexes.set(column, flex);
        }
        
        for (const [column, flex] of columnFlexes) {
            const cssWidth = flex / flexWidthTotal * 100;
            
            columns.set(column.fullPath, {
                ...column,
                flex,
                cssWidth
            });
        }
        
        Model.activeSchemaColumns = columns;
    },
    getActiveSchema: function() {
        return Model.activeSchema;
    },
    
    toggleSort: function(fieldPath) {
        if (Model.activeSchemaSort[fieldPath]) {
            Model.activeSchemaSort[fieldPath] *= -1;
        } else {
            Model.activeSchemaSort = {
                [fieldPath]: 1
            };
        }
        
        CollectionController.load();
    },
    toggleVisible: function(field) {
        const columns = Model.activeSchemaColumns;
        const key = field.fullPath;
        
        if (columns.has(key)) {
            columns.delete(key);
        } else {
            columns.set(key, {...field});
        }

        SchemaController.setColumnWidths();
        CollectionController.load();
    },
    toggleFilter: function(field, value) {
        const activeSchema = SchemaController.getActiveSchema();
        const filters = Model.activeSchemaFilters;
        const path = field.fullPath;

        if (filters[path]) {
            if (filters[path].has(value)) {
                filters[path].delete(value);
            } else {
                filters[path].add(value);
            }

        } else {
            filters[path] = new Set([value]);
        }

        CollectionController.load(activeSchema, function() {
            CollectionController.filtersSelector();
        });
    },
    clearFilters: function() {
        var activeSchema = SchemaController.getActiveSchema();
        Model.activeSchemaFilters = {};
        CollectionController.load(activeSchema, function() {
            CollectionController.filtersSelector();
        });
    }
}