import { config } from './config';

import { schemas } from '../js/collectionSchemas';

export const Model = {
    version: '0.7.1',
    systemTitle: 'FCCC Switchboard',
    serverDomain: config.serverDomain,
    apiDomain: config.apiDomain,
    collectionChunkSize: 50,
    switches: [
        {
            name: 'Dealer Locator',
            authority: 'DTNA',
            direction: 'in',
            script: 'dtnaDealerLocator/switch.js'
        },
        {
            name: 'SRT Excel Models',
            authority: 'FCCC',
            direction: 'in',
            script: 'DISABLED/srtCSV/switch.js'
        },
        {
            name: 'RV Advisor',
            authority: 'FCCC',
            direction: 'out',
            script: 'rvAdvisor/switch.js'
        },
        {
            name: 'Business Case Tool Reference',
            authority: 'FCCC',
            direction: 'out',
            script: 'bctReference/switch.js'
        },
        {
            name: 'Owner HQ',
            authority: 'FCCC',
            direction: 'out',
            script: 'ownerHq/switch.js'
        }
    ],
    activeCollection: [],
    collectionSearchQuery: '',
    activeSchema: null,
    activeSchemaColumns: null,
    activeSchemaFilters: null,
    activeSchemaSort: null,
    saveRequests: {},
    saveTimers: {},
    beforeSaveHandlers: {},
    afterSaveHandlers: {},
    schemas,
    activeUser: {},
    formulas: {
        gcwr: function(model) {
            if (model.specs.transmission.model) {
                switch (String(model.specs.transmission.model)) {
                    case '2100':
                        return [30000];
                    case '2200':
                        return [26000];
                    case '2350':
                        return [30000];
                    case '2500':
                        return [33000];
                    case '3000':
                    case '4000':
                        if (model.specs.weightRating.gvwr.total > 0) {
                            if (model.specs.hitch.capacity) {
                                return [model.specs.weightRating.gvwr.total+model.specs.hitch.capacity];
                            } else {
                                return [model.specs.weightRating.gvwr.total];
                            }
                        } else {
                            return [];
                        }
                }
            } else {
                return [];
            }
        },
        gvwr: function(model) {
            if ((model.specs.weightRating.gvwr.front) && (model.specs.weightRating.gvwr.rear)) {
                return [model.specs.weightRating.gvwr.front+model.specs.weightRating.gvwr.rear+model.specs.weightRating.gvwr.tag];
            } else {
                return [];
            }
        },
        steeringColumn: function(model) {
            return [model.srtTemp.controls.steeringColumn];
        },
        steeringWheel: function(model) {
            return [model.srtTemp.controls.steeringWheels];
        },
        wheelDiameter: function(model) {
            if (model.specs.wheels.rear.dimensions.diameter != '') {
                return [model.specs.wheels.rear.dimensions.diameter];
            } else if (model.specs.wheels.front.dimensions.diameter != '') {
                return [model.specs.wheels.front.dimensions.diameter];
            } else {
                return [];
            }
        },
        wheelWidth: function(model) {
            if (model.specs.wheels.rear.dimensions.width != '') {
                return [model.specs.wheels.rear.dimensions.width];
            } else if (model.specs.wheels.front.dimensions.width != '') {
                return [model.specs.wheels.front.dimensions.width];
            } else {
                return [];
            }
        }
    }
}