import { Model } from '../../Model';
import { MetaController } from '../../controllers/meta';
import { SchemaController } from '../../controllers/schema';
import { CollectionController, CollectionView } from '../collection';
import { DashboardController } from '../dashboard';
import { ItemController } from './item.controller';
import { Inputs } from './inputs.view';
import { ModalView } from '../../views/modal';
import { viewChange } from '../../views/change';

export const ItemView = {
    dom: function() {
        var item = ItemController.getActiveItem();
        var activeSchema = SchemaController.getActiveSchema();
        var sendingPDF = false;


        return $(`<div id="item-${item._id}" class="item" />`)
            .append(
                $('<div/>')
                    .addClass('breadcrumbs')
                    .html('Components / '+activeSchema.displayName+' / '+MetaController.getByPath(item, activeSchema.itemPath))
                    .on('click', function() {
                        DashboardController.init();
                    })
            )
            .append(
                $('<div/>')
                    .addClass('collection-header')
                    .append(
                        $('<div/>')
                            .addClass('buttons')
                            .append(
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('duplicate')
                                    .text('Duplicate')
                                    .on('click', function() {
                                        ItemController.duplicate(item._id);
                                    })
                            )
                            .append(
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('delete')
                                    .text('Delete')
                                    .on('click', function() {
                                        ItemController.delete(item._id);
                                    })
                            )
                            //.append(
                            //    $('<div/>')
                            //        .addClass('button')
                            //        .addClass('revert')
                            //        .text('Revert')
                            //)
                            //.append(
                            //    $('<div/>')
                            //        .addClass('button')
                            //        .addClass('publish')
                            //        .text('Publish')
                            //)
                            // .append(
                            //     $('<div/>')
                            //         .addClass('button')
                            //         .addClass('preview')
                            //         .text('Preview')
                            //         .on('click', function() {
                            //             window.open(activeSchema.previewUrl+'?id='+item._id, '_blank');
                            //         })
                            // )

                            .append(
                                activeSchema.schemaName == "model" ?                                
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('render')
                                    .text('Update PDF')
                                    .on('click', function() {
                                        var pdfButton = $(this)
                                        pdfButton.text('Updating PDF...').addClass('disabled')
                                        if (!sendingPDF) {
                                            $.ajax({
                                                type: 'POST',
                                                url: Model.serverDomain+'/render/specsheets',
                                                data: {
                                                    itemId: item._id
                                                },
                                                beforeSend: function(xhr) {
                                                    sendingPDF = true;
                                                    if (localStorage.token) {
                                                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                                                    }
                                                },
                                                success: function(data) {
                                                    console.log(data);
                                                    // reload this model editor
                                                    ItemController.load(Model.activeItem._id, function() {
                                                        const nonce = new Date().toISOString();
                                                        setTimeout(function() {
                                                            window.open(data.url + '?t=' + nonce, '_blank');
                                                        }, 1000);
                                                    });
                                                },
                                                error: function() {
                                                    alert("Sorry, an error has occurred.");
                                                },
                                                complete: function() {
                                                    sendingPDF = false;
                                                    pdfButton.text('Update PDF').removeClass('disabled')                                     
                                                }
                                            });

                                        }
                                    })
                                     : '' 
                            ) 
                            .append(
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('change-log-button')
                                    .text('View History')
                                    .on('click', function() {
                                        viewChange.item.init(activeSchema, item._id);
                                    })
                            )
                            .append(
                                $(activeSchema.schemaName == 'user' ? '<div/>' : '')
                                    .addClass('button')
                                    .addClass('change-log-button')
                                    .text('Send Kiosk Email')
                                    .on('click', function() {
                                        $.ajax({
                                            type: 'POST',
                                            url: Model.apiDomain+'/kiosk/sendEmailWithQR',
                                            data: {
                                                userId: item._id
                                            },
                                            success: function(data) {
                                                console.log('daterr', data)
                                            },
                                            error: function() {
                                                console.log('oh no')
                                            }
                                        });
                                    })
                            )
                    )
                    .append(
                        $('<div/>')
                            .addClass('type')
                            .html('Component')
                    )
                    .append(
                        $('<div/>')
                            .addClass('collection-name')
                            .text(activeSchema.displayName)
                            .on('click', function() {
                                $('.item').replaceWith(CollectionView.dom());
                                CollectionController.load(SchemaController.getActiveSchema(), function() {
                                    console.log('Collection loaded: '+activeSchema.displayName);
                                });
                            })
                    )
            )
            .append(
                $('<div/>')
                    .addClass('record-header')
                    .append(
                        $('<div/>')
                            .addClass('metrics')
                            .append(
                                $('<div/>')
                                    .addClass('metric')
                                    .append(
                                        $('<div/>')
                                            .addClass('label')
                                            .text('Last Updated:')
                                    )
                                    .append(
                                        $('<div/>')
                                            .addClass('value')
                                            .addClass('last-updated')
                                            .text(new Date(item.updateTime).toLocaleString())
                                    )
                            )
                            .append(
                                $('<div/>')
                                    .addClass('metric')
                                    //.append(
                                    //    $('<div/>')
                                    //        .addClass('label')
                                    //        .text('Last Published:')
                                    //)
                                    .append(
                                        $('<div/>')
                                            .addClass('value')
                                            .addClass('last-published')
                                    )
                            )
                    )
                    .append(
                        $('<div/>')
                            .addClass('record-title')
                            .text(MetaController.getByPath(item, activeSchema.itemPath))
                    )
                    .append(
                        $('<div/>')
                            .addClass('record-container')
                            .append(ItemView.itemFields(SchemaController.get(activeSchema.schemaName).fields))
                            // .append(
                            //     $('<pre/>')
                            //         .html(JSON.stringify(ItemController.getActiveItem(), null, 4))
                            // )
                    )
            );
    },
    itemFields: function(fields) {
        let itemElems = [];
        
        for (const field of fields) {
            // NOTE: We probably could use field.fullPath here, but that's only relevant for lists anyway, and we haven't thought through save hooks with lists
            if (field.path && typeof field.afterSave == 'function') {
                ItemController.addAfterSaveHandler(field.path, field.afterSave);
            }
            
            if (!field.hide) {
                let fieldElems;
                
                switch (field.type) {
                    case 'category':
                        const catElems = [];
                        
                        if ((field.name) && (!field.hideName)) {
                            catElems.push(
                                $('<div/>')
                                    .addClass('clear')
                            );
                            catElems.push(
                                $('<div/>')
                                    .addClass('header')
                                    .text(field.name)
                            );
                        }
                        if ((field.fields) && (field.fields.length > 0)) {
                            catElems.push(
                                $('<div/>')
                                    .addClass('category')
                                    .append(
                                        ItemView.itemFields(field.fields)
                                    )
                                    .append(
                                        $('<div/>')
                                            .addClass('clear')
                                    )
                            );
                        }
                        
                        fieldElems = catElems;
                        break;
                    
                    case 'note':
                        fieldElems = Inputs.note(field);
                        break;

                    case 'text':
                    case 'number':
                    case 'int':
                    case 'url':
                    case 'phone':
                    case 'email':
                    case 'color':
                        fieldElems = Inputs.text(field);
                        break;

                    case 'textarea':
                        fieldElems = Inputs.textarea(field);
                        break;

                    case 'list':
                        fieldElems = Inputs.list(field);
                        break;

                    case 'select':
                        fieldElems = Inputs.select(field);
                        break;

                    case 'boolean':
                        fieldElems = Inputs.trueFalse(field);
                        break;

                    case 'booleanList':
                        fieldElems = Inputs.listToggle(field);
                        break;

                    case 'reportField':

                        break;

                    case 'img':
                        fieldElems = Inputs.img(field);
                        break;

                    case 'upload':
                        fieldElems = Inputs.upload(field);
                        break;

                    // case 'file':
                    //     fieldsElem.push(
                    //         Inputs.file(field)
                    //     );
                    //     break;

                    case 'password':
                        fieldElems = Inputs.password(field);
                        break;
                    
                    case 'date' : 
                        fieldElems = Inputs.date(field);
                        break;
                    
                    case 'keyValueList':
                        fieldElems = Inputs.keyValueList(field);
                        break;

                    case 'reset':
                        fieldElems = Inputs.reset(field);
                        break;
                }
                
                // console.log({fieldElems});
                
                if (!Array.isArray(fieldElems)) {
                    fieldElems = [fieldElems];
                }
                
                if (fieldElems && typeof field.showWhen == 'function') {
                    let isShown = true;
                    const context = {
                        ItemController,
                        item: ItemController.getActiveItem()
                    };
                    
                    const showOrHideField = () => {
                        const shouldShow = !!field.showWhen(context);
                        if (shouldShow == isShown) return;
                        
                        isShown = shouldShow;
                        for (const el of fieldElems) {
                            if (isShown) {
                                el.removeClass('hidden');
                            } else {
                                el.addClass('hidden');
                            }
                        }
                    };
                    
                    showOrHideField();
                    ItemController.addBeforeSaveHandler('*', showOrHideField);
                }
                
                if (fieldElems) {
                    itemElems.push(...fieldElems);
                }

                if (field.lineAfter) {
                    itemElems.push(
                        Inputs.divider()
                    );
                }
            }
        }

        return itemElems;
    },
    showSuggestions: function(options, suggestions, value) {
        $('.suggestions').remove();

        var targetClass = MetaController.dashPath(options.path);

        var suggestionsElems = [];
        $.each(suggestions, function(i, suggestion) {
            if (suggestion != '') {
                suggestionsElems.push(
                    $('<div/>')
                        .addClass('suggestion')
                        .text(suggestion)
                        .on('mousedown', function(event) {
                            event.stopPropagation();
                        })
                        .on('click', function() {
                            $('.'+targetClass)
                                .val(suggestion);
                            $('.suggestions').remove();
                            ItemController.save(options.path, suggestion, options.type);
                        })
                        .on('contextmenu', function(e) {
                            e.preventDefault();

                            var globalModalElem = $('<div/>');

                                globalModalElem
                                    .append(
                                        $('<div/>')
                                            .text('Replace all instances of')
                                    )
                                    .append(
                                        $('<div/>')
                                            .append(
                                                $('<input/>')
                                                    .addClass('input-find')
                                                    .attr('type', 'text')
                                                    .val(suggestion)
                                            )
                                    )
                                    .append(
                                        $('<div/>')
                                            .text('with')
                                    )
                                    .append(
                                        $('<div/>')
                                            .append(
                                                $('<input/>')
                                                    .addClass('input-replace')
                                                    .attr('type', 'text')
                                                    .val(value)
                                            )
                                    )
                                    .append(
                                        $('<div/>')
                                            .addClass('button')
                                            .text('Go')
                                            .on('click', function() {
                                                var activeSchema = SchemaController.getActiveSchema();
                                                CollectionController.globalFindReplace($('.input-find').val(), $('.input-replace').val(), activeSchema.collectionName, options.path, options.type);
                                            })
                                    );

                            ModalView.init('Global Find/Replace', globalModalElem);

                        })
                );
            }
        });

        var targetWidth = $('.'+targetClass).parent().width();

        $('.'+targetClass).parent().append(
            $('<div/>')
                .addClass('suggestions')
                .css('width', targetWidth+'px')
                .append(suggestionsElems)
        );

        $('.record-container').off('mousedown').on('mousedown', function() {
            $('.suggestions').remove();
        });
    }
}