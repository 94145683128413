
import '../css/switchboard.scss';

import 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import 'tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';

import { Controller } from './Controller';

Controller.init();
