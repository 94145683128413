import { config } from '../config.js';

export const viewChange = {
    item: {
        init: function(schema, itemId) {

            // Define path crumbs
            const pathNames = getPathCrumbs(schema.fields);

            if ($('.change-log').length) {
                $('.change-log').remove();
                $('.record-container').css('right', '0');
            } else {
                this.viewInit();
                getChanges(schema, itemId, function(changeData) {
                    console.log('Got Changes: ');
                    console.log(changeData);
                    $('.change-log .content').html('');
                    const users = {};
                    $.each(changeData.users, function(u, user) {
                        users[user._id] = user;
                    });

                    $.each(changeData.changes, function(c, change) {
                        $('.change-log .content').append(
                            $('<div/>')
                                .addClass('change')
                                .append(
                                    $('<div/>')
                                        .text(pathNames[change.path]+':')
                                )
                                .append(
                                    $('<div/>')
                                        .addClass('value')
                                        .text(change.value)
                                )
                                .append(
                                    $('<div/>')
                                        .text(users[change.userId].firstName+' '+users[change.userId].lastName+' at '+new Date(change.updateTime).toLocaleString())
                                )
                        );
                    });
                });
            }

        },
        viewInit: function() {
            $('.record-header')
                .append(
                    $('<div/>')
                        .addClass('change-log')
                        .addClass('side-window')
                        .append(
                            $('<div/>')
                                .addClass('close')
                                .text('x')
                                .on('click', function() {
                                    $('.side-window').remove();
                                    $('.record-container').css('right', '0');
                                })
                        )
                        .append(
                            $('<div/>')
                                .addClass('header')
                                .text('History')
                        )
                        .append(
                            $('<div/>')
                                .addClass('content')
                                .text('Loading . . .')
                        )
                        // .append(
                        //     $('<div/>')
                        //         .addClass('footer')
                        // );
                );
            $('.record-container').css('right', '250px');
        }
    }
};


function getChanges(schema, itemId, callback) {
    $.ajax({
        type: 'POST',
        url: config.serverDomain+'/changes/load',
        data: {
            itemId: itemId,
            // schema: schema
        },
        beforeSend: function(xhr) {
            if (localStorage.token) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
            }
        },
        success: function(data) {
            callback(data);
        },
        error: function() {
            //Switchboard.Controller.User.logout();
        }
    });
}

function getPathCrumbs(fields) {
    var paths = {};
    $.each(fields, function(f, field) {
        if (field.type == 'category') {
            $.extend(paths, getPathCrumbs(field.fields));
        } else {
            if (field.columnName) {
                paths[field.path] = field.columnName;
            } else {
                paths[field.path] = field.name;
            }
        }
    });

    return paths;
}
