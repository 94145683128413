import { SwitchController } from './switch.controller';

export const SwitchesView = {
    dom: function() {
        var switchesIn = [];
        var switchesOut = [];
        $.each(SwitchController.getAll(), function(i, switchMeta) {
            switch(switchMeta.direction) {
                case 'in':
                    switchesIn.push(SwitchView.dom(switchMeta));
                    break;

                case 'out':
                    switchesOut.push(SwitchView.dom(switchMeta));
                    break;
            }
        });

        return $('<div/>')
            .addClass('switches')
            .append(
                $('<div/>')
                    .addClass('in')
                    .append(switchesIn)
            )
            .append(
                $('<div/>')
                    .addClass('db')
                    .text('Database')
            )
            .append(
                $('<div/>')
                    .addClass('out')
                    .append(switchesOut)
            );
    }
}

export const SwitchView = {
    dom: function(switchMeta) {
        return $('<div/>')
            .addClass('switch')
            .text(switchMeta.name)
            .on('click', function() {
                SwitchController.activate(switchMeta, function() {});
            });
    }
}