import { Model } from '../../Model';
import { UserView } from './user.view';
import { Controller } from '../../Controller';
import { DashboardController } from '../dashboard';
import jwt from 'jsonwebtoken';

export const UserController = {
    register: function() {
        $.ajax({
            type: 'POST',
            url: `${Model.serverDomain}/user/register`,
            data: {
                firstName: 'First',
                lastName: 'Last',
                email: 'test@fai2.com',
                password: ''
            },
            dataType: 'json',
            success: function(response) {
                console.log(response);
            }
        });
    },
    // * With the data reorg, we can probably just use the regular User IDs instead of nfcIds
    // // getNewId: function(callback) {
    // //     // console.log("Model", Model)
    // //     $.ajax({
    // //         type: 'POST',
    // //         url: Model.serverDomain+'/user/getNewId',
    // //         beforeSend: function(xhr) {
    // //             if (localStorage.token) {
    // //                 xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
    // //             }
    // //         },
    // //         success: function(response) {
    // //             // console.log('response:', response)
    // //             // console.log('response.newId:', response.newId)
    // //             if (callback) callback(response.newId);
    // //         },
    // //         error: function(response) {
    // //             console.log('error:', response)
    // //             // // Show login view
    // //             // $('body').html(View.User.login());
    // //             // $('.login .email').focus();
    // //         }
    // //     });
    // // },
    login: function(email, password) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'POST',
                url: `${Model.serverDomain}/user/login`,
                data: {
                    email: email,
                    password: password
                },
                dataType: 'json',
                success: function(response) {
                    localStorage.token = response.token;
                    //UserController.loadData();
                    Model.activeUser = {
                        email: response.email,
                        permissions: response.permissions
                    };
                    const urlParams = new URLSearchParams(window.location.search);
                    const callback = urlParams.get("callback");
                    if (callback) {
                        const callbackUrl = new URL(callback);
                        callbackUrl.searchParams.append('accessToken', localStorage.token);
                        return window.location.replace(callbackUrl.href);
                    }
                    DashboardController.init();
                    resolve(response);
                },
                error: function(response) {
                    reject(response.responseJSON);
                }
            });
        });
    },
    remoteLogin: function(token) {
        var uri = window.location.href.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = Model.serverDomain; //uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }

        $.ajax({
            type: 'POST',
            url: `${Model.serverDomain}/user/remoteLogin`,
            data: {
                token: token
            },
            dataType: 'json',
            success: function(response) {
                localStorage.token = response.token;
                //UserController.loadData();
                Model.activeUser = {
                    email: response.email,
                    permissions: response.permissions
                };
                const urlParams = new URLSearchParams(window.location.search);
                const callback = urlParams.get("callback");
                
                if (callback) {
                    const callbackUrl = new URL(callback);
                    callbackUrl.searchParams.append('accessToken', localStorage.token);
                    return window.location.replace(callbackUrl.href);
                }
                DashboardController.init();
                // Controller.Collection.init('model');
                //window.location = Model.serverDomain;
            },
            error: function(response) {
                $('body').html(UserView.login());
                $('.login .email').focus();
                //alert(response.responseJSON.message);
                // $('.login .password').val('').focus();
            }
        });
    },
    logout: function() {
        Model.activeUser = {};
        localStorage.removeItem('token');
        localStorage.clear();
        Controller.init();
    },
    loadActiveUser: function(callback) {
        $.ajax({
            type: 'POST',
            url: `${Model.serverDomain}/user/activeUser`,
            data: {},
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.token}`);
                }
            },
            dataType: 'json',
            success: function(response) {
                Model.activeUser = {
                    email: response.email,
                    permissions: response.permissions
                };
                callback();
            },
            error: function(response) {
                // Show login view
                $('body').html(UserView.login());
                $('.login .email').focus();
            }
        });
    },
    getActiveUser: function() {
        return Model.activeUser;
    },
    checkPermission: function(permissionKeys) {
        var allowed = false;
        var grantedPermissions = UserController.getActiveUser().permissions;
        $.each(permissionKeys, function(p, permissionKey) {
            // console.log(permissionKey);
            // console.log(grantedPermissions);
            // console.log(grantedPermissions.indexOf(permissionKey));
            if (grantedPermissions.indexOf(permissionKey) != -1) {
                allowed = true;
            }
        });

        return allowed;
    },
    loadData: function() {
        $.ajax({
            type: 'GET',
            url: `${Model.serverDomain}/user/profile`,
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.token}`);
                }
            },
            success: function(data) {
                //alert('Hello ' + data.firstName + '! You have access to /api/profile.');
            },
            error: function() {
                //alert("Sorry, you are not logged in.");
            }
        });
    },
    resetPassword: function(resetToken) {
        // check if resetToken is valid
        if (resetToken && jwt.decode(resetToken) && jwt.decode(resetToken).exp > Date.now() / 1000) {
        // if valid, show reset password view
            $("body").html(UserView.resetPassword());
            $('.login .password').focus();
            return;
        }
        const url = new URL(window.location.href);
        url.searchParams.delete('token');
        url.searchParams.delete('email');
        url.pathname = "";
        window.location.href = url.toString();
        $('body').html(UserView.forgotPassword());
        $('.login .email').focus();
    },

}