import { MetaController } from '../../controllers/meta';
import { UserController } from './user.controller';

export const UserView = {
    login: function() {
        return $('<div/>')
            .addClass('login')
            .append(
                $('<div/>')
                    .addClass('stripe')
                    .append(
                        $('<div/>')
                            .addClass('title')
                            .text(MetaController.getSystemTitle())
                    )
                    .append(
                        $('<div/>')
                            .addClass('fields')
                            .append(
                                $('<p class="error-message">')
                                    .hide()
                            )
                            .append(
                                $('<input/>')
                                    .addClass('email')
                                    .attr('type', 'email')
                                    .attr('placeholder', 'email')
                            )
                            .append(
                                $('<input/>')
                                    .addClass('password')
                                    .attr('type', 'password')
                                    .attr('placeholder', 'password')
                                    .on('keyup', function(e) {
                                        if (e.keyCode == 13) {
                                            $('.login-btn').trigger('click');
                                        }
                                    })
                            )
                            .append(
                                $('<div/>')
                                    .addClass('login-btn')
                                    .text('Sign In')
                                    .on('click', function() {
                                        const email = $('input.email').val();
                                        
                                        UserController.login(email, $('input.password').val())
                                            .catch(error => {
                                                let message = error.message;
                                                const daimlerRe = /@daimler.com$/i;
                                                
                                                if (daimlerRe.test(email)) {
                                                    message += ' Try logging in with your daimlertruck.com email address.'
                                                }
                                                
                                                $('.error-message')
                                                    .text(message)
                                                    .slideDown(200);
                                                
                                                $('.login .password').val('').focus();
                                            });
                                    })
                            )
                    )
            );
    }
}