import { DashboardView } from './dashboard.view';

export const DashboardController = {
    init: function() {
        // console.log(Switchboard);

        $('body').html(DashboardView.temp());

        // If there is just one menu item, call its click action
        if ($('.menu-item').length == 1) {
            $('.menu-item')[0].click();
        }
    }
}