import { Model } from '../../Model';
import { MetaController } from '../../controllers/meta';
import { SchemaController } from '../../controllers/schema';
import { DashboardController } from '../dashboard';
import { SwitchController } from '../switch';
import { ItemController } from '../item';
import { CollectionController } from './collection.controller';

export const CollectionView = {
    dom: function() {
        var activeSchema = SchemaController.getActiveSchema();
        console.log('activeSchema:');
        console.log(activeSchema);
        var buttonsLowerElem = $('<div/>')
            .addClass('buttons');
        var processing = false;
        if (typeof activeSchema.importSwitchIndex !== 'undefined') {
            buttonsLowerElem.append(

                $('<div/>')
                    .addClass('button')
                    .addClass('import')
                    .text('Import')
                    .on('click', function() {
                        if (processing === false){
                            processing = true;
                            var $btn = $(this);
                            $btn.addClass('processing');
                            SwitchController.activate(Model.switches[activeSchema.importSwitchIndex], function() {
                                CollectionController.load(SchemaController.getActiveSchema(), function() {
                                    $btn.removeClass('processing');
                                    processing = false;

                                });
                            });
                        }
                    })
            );
        }


        if (typeof activeSchema.exportSwitchIndex !== 'undefined') {
            buttonsLowerElem.append(
                $('<div/>')
                    .addClass('button')
                    .text('Export')
                    .on('click', function() {


                        var $btn = $(this);
                        $btn.addClass('processing');
                        SwitchController.activate(Model.switches[activeSchema.exportSwitchIndex], function() {
                            $btn.removeClass('processing');
                        });

                    })
            );
        }

        buttonsLowerElem.append(
            $('<div/>')
                .addClass('button')
                .text('Download .CSV')
                .on('click', function() {
                    var $btn = $(this);
                    $btn.addClass('processing');
                    CollectionController.downloadCSV(SchemaController.getActiveSchema(), function() {
                        $btn.removeClass('processing');
                    });
                })
        );

        buttonsLowerElem.append(
            $('<div/>')
                .addClass('button')
                .text('Fields')
                .on('click', function() {
                    CollectionController.fieldsSelector();
                })
        );


        return $('<div/>')
            .addClass('collection')
            .append(
                $('<div/>')
                    .addClass('breadcrumbs')
                    .html('Components > ')
                    .on('click', function() {
                        DashboardController.init();
                    })
            )
            .append(
                $('<div/>')
                    .addClass('collection-header')
                    .append(
                        $('<div/>')
                            .addClass('buttons')
                            .append(
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('add-new')
                                    .text('+ Add New')
                                    .on('click', function() {
                                        ItemController.add();
                                    })
                            )
                            .append(
                                $('<div/>')
                                    .addClass('button')
                                    .addClass('show-filters')
                                    //.text('Show Filters')
                                    .html('<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1595 295q17 41-14 70l-493 493v742q0 42-39 59-13 5-25 5-27 0-45-19l-256-256q-19-19-19-45v-486l-493-493q-31-29-14-70 17-39 59-39h1280q42 0 59 39z"/></svg>Show Filters')
                                    .on('click', function() {
                                        CollectionController.filtersSelector();
                                    })
                            )
                    )
                    .append(
                        $('<div/>')
                            .addClass('type')
                            .html('Component')
                    )
                    .append(
                        $('<div/>')
                            .addClass('collection-name')
                    )
            )
            .append(
                $('<div/>')
                    .addClass('records-header')
                    .append(
                        buttonsLowerElem
                    )
                    .append(
                        $('<div/>')
                            .addClass('metrics')
                            .append(
                                $('<div/>')
                                    .addClass('metric')
                                    .append(
                                        $('<div/>')
                                            .addClass('label')
                                            .text('Records:')
                                            .on('click', function() {
                                                $.ajax({
                                                    type: 'POST',
                                                    url: Model.serverDomain+'/render/renderAll',
                                                    beforeSend: function(xhr) {
                                                        if (localStorage.token) {
                                                            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                                                        }
                                                    },
                                                    success: function(data) {
                                                        console.log(data);

                                                    },
                                                    error: function() {

                                                    }
                                                });
                                            })
                                    )
                                    .append(
                                        $('<div/>')
                                            .addClass('value')
                                            .addClass('record-count')
                                            .text(0)
                                    )
                            )
                            .append(
                                $('<div/>')
                                .addClass('search-bar')
                                    .append(
                                        $('<input type="text" placeholder="Search" />').addClass('search-input')
                                            .on("input", function() {
                                                CollectionController.searchCollection($(this).val())
                                            })
                                      )
                            )
                    )
            )
            .append(
                $('<div/>')
                    .addClass('table-header')
            )
            .append(
                $('<div/>')
                    .addClass('records-container')
            );
    },
    fieldsSelect: function() {
        const schema = SchemaController.getActiveSchema();
        
        // NOTE: schema.fields here still needs to stay intact
        const fields = CollectionView.fieldsSelectNode(schema.fields);

        return $('<div/>')
            .addClass('side-window')
            .append(
                $('<div/>')
                    .addClass('close')
                    .text('x')
                    .on('click', function() {
                        $('.side-window')
                            .remove();
                    })
            )
            .append(
                $('<div/>')
                    .addClass('header')
                    .text('Select Fields')
            )
            .append(
                $('<div/>')
                    .addClass('content')
                    .addClass('fields-select')
                    .append(fields)
            )
            .append(
                $('<div/>')
                    .addClass('footer')
            );
    },
    fieldsSelectNode: function(fields) {
        const fieldsUl = $('<ul/>');
        
        for (const field of fields) {
            switch (field.type) {
                case 'category':
                    fieldsUl.append(
                        $('<li/>')
                            .append(
                                $('<div/>')
                                    .addClass('category')
                                    .text(field.name)
                            )
                            .append(CollectionView.fieldsSelectNode(field.fields))
                    );
                    break;

                case 'list':
                    fieldsUl.append(
                        $('<li/>')
                            .append(
                                $('<div/>')
                                    .addClass('category')
                                    .text(field.name)
                            )
                            .append(CollectionView.fieldsSelectNode(field.fields))
                    );
                    break;

                case 'booleanList':
                    break;

                default:
                    const fieldLi = $('<li/>')
                        .text(field.name)
                        .on('click', function() {
                            $(this).toggleClass('active');
                            SchemaController.toggleVisible(field);
                        });
                    
                    if (Model.activeSchemaColumns.has(field.fullPath)) {
                        // This field option is active
                        fieldLi.addClass('active');
                    }

                    fieldsUl.append(fieldLi);
            }
        }
        
        return fieldsUl;
    },
    filtersSelector: function() {
        const filters = [];
        const records = CollectionController.get().records;
        //console.log(records);
        
        for (const field of Model.activeSchemaColumns.values()) {
            //console.log(field);

            const availableValuesFlat = [];
            const availableValues = [];
            $.each(records, function(i, record) {
                const value = MetaController.getByPath(record, field.fullPath);
                if ((value != undefined) && (value != '')) {
                    const valueIndex = availableValuesFlat.indexOf(value);
                    if (valueIndex == -1) {
                        availableValuesFlat.push(value);

                        availableValues.push({
                            value: value,
                            count: 1
                        });
                    } else {
                        availableValues[valueIndex].count++;
                    }
                }
            });

            switch (field.type) {
                case 'booleanz':

                    break;

                case 'list':

                    break;

                default:
                    //if ((availableValuesFlat.length < 35) && (availableValuesFlat.length != records.length)) {
                    if ((availableValuesFlat.length < 35)) {
                        availableValues.sort(function(a, b) {return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);} );

                        const options = [];
                        $.each(availableValues, function(v, option) {
                            const optionElem = $('<div/>')
                                .addClass('option')
                                .text(option.value+' ('+option.count+')')
                                .on('click', function() {
                                    SchemaController.toggleFilter(field, option.value);
                                    $(this).toggleClass('active');

                                });
                            
                            // FIXME: Set up Babel to do optional chaining
                            if (Model.activeSchemaFilters[field.fullPath] && Model.activeSchemaFilters[field.fullPath].has(option.value)) {
                                optionElem.addClass('active');
                            }

                            options.push(optionElem);
                        });

                        const filterElem = $('<div/>')
                            .addClass('field')
                            .append(
                                $('<div/>')
                                    .addClass('title')
                                    .text(field.columnName || field.name)
                            )
                            .append(
                                $('<div/>')
                                    .addClass('options')
                                    .append(options)
                            );

                        filters.push(filterElem);
                }
            }
        }

        return $('<div/>')
            .addClass('side-window')
            .append(
                $('<div/>')
                    .addClass('close')
                    .text('x')
                    .on('click', function() {
                        $('.side-window')
                            .remove();
                    })
            )
            .append(
                $('<div/>')
                    .addClass('header')
                    .text('Filters')
            )
            .append(
                $('<div/>')
                    .addClass('content')
                    .addClass('filters-select')
                    .append(filters)
            )
            .append(
                $('<div/>')
                    .addClass('footer')
                    .append(
                        $('<div/>')
                            .addClass('button')
                            .text('Clear All')
                            .on('click', function() {
                                SchemaController.clearFilters();
                            })
                    )
            );
    },
    headers: function(activeColumns) {
        const headers = [];

        for (const field of activeColumns.values()) {
            headers.push(
                $('<div/>')
                    .addClass('field')
                    .css('width', field.cssWidth+'%')
                    .text(field.columnName || field.name)
                    .on('click', function() {
                        SchemaController.toggleSort(field.fullPath);
                    })
                    .on('contextmenu', function(e) {
                        e.preventDefault();
                        console.log('contextmenu trigger: '+field.name);
                    })
            );
        }

        return headers;
    },
    record: function(activeColumns, record) {
        const recordFields = [];

        for (const field of activeColumns.values()) {
            let fieldValue = '';
            
            fieldValue = MetaController.getByPath(record, field.fullPath, true);
            //fieldValue = 'test';

            switch (field.type) {
                case 'boolean':
                    if (fieldValue) {
                        fieldValue = 'Yes';
                    } else {
                        fieldValue = 'No';
                    }
                    break;

                case 'dateTime':
                    const date = new Date(fieldValue);
                    fieldValue = date.toLocaleString();
                    break;

                case 'img':
                    fieldValue = $('<img/>')
                        .css({
                            'width': '50px'
                        })
                        .attr('src', Model.serverDomain+'/img/getThumb?fileId='+fieldValue+'&width=50&time='+Date.now());
                    break;
            }

            if ((fieldValue != '') && (fieldValue != undefined)) {
                if (field.suffix) {
                    fieldValue += '<span>'+field.suffix.text+'</span>';
                }
                if (field.prefix) {
                    fieldValue = '<span>'+field.prefix.text+'</span>'+fieldValue;
                }
            }


            recordFields.push(
                $('<div/>')
                    .addClass('field')
                    .css('width', field.cssWidth+'%')
                    //.css('width', '100px')
                    .html(fieldValue)
            );
        }

        return $('<div/>')
            .addClass('record')
            .addClass('item-'+record._id)
            .append(recordFields)
            .on('click', function() {
                ItemController.load(record._id, function() {
                    //$('.collection').replaceWith(View.Item.dom());
                });
            });
    },
    
    records: function(activeColumns, records) {
        const recordsElem = [];

        $.each(records, function(i, record) {
            recordsElem.push(CollectionView.record(activeColumns, record));
        });

        return recordsElem;
    }
}